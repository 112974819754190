import { Injectable } from '@angular/core';

import { ATFP } from '../shared/atfp';
import { User } from '../shared/user';

import { baseURL } from '../shared/baseurl';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ProcessHttpmsgService } from './process-httpmsg.service';
import { AuthService } from './auth.service';

import { map, delay, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JudgementService {

  judgement: ATFP;
  user: User;

  constructor(
    private _http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService,
    private authService: AuthService
  ) { }

  validateTribunal( tribunal ){
    // validate if the tribunals are present or not
    let t = [
    'Supreme Court','SUPREME COURT','MumbaiHC','DelhiHC',
    'KeralaHC','KarnatakaHC','RajasthanHC','GujaratHC','PunjabHC','OrrisaHC',
    'CalcuttaHC','MadrasHC','TripuraHC','UttarakhandHC','HimachalPradeshHC','RajasthanHC','MpHC',
    'AllahabadHC','HyderabadHC','PatnaHC',
    'ATFP','BIFR','CAT','CESTAT',
    'DRT','IPAB','ITAT','MHRERA',
    'MAT','MCDRC','MILC','MACT','MSCA',
    'MST','NCDRC','NCLAT','PNGRB','RCT','SAT'
  ];
    return t.indexOf( tribunal )!= -1 ? true : false;
  }

  getPath(tribunal){
    if(!this.validateTribunal(tribunal)) return false;
    if(tribunal === 'Supreme Court' || tribunal === 'SUPREME COURT'){
      return 'scdata';
    }
    if(tribunal === 'MumbaiHC'){
      return 'mhcdata';
    }
    return tribunal.toLowerCase()+'data';
  }

  getJudgmentsByTribunal(tribunal: string): Observable<any> {

/**
 * Dharmil
 */
    console.log("getJudgmentsByTribunal()");
// =========================================================================

// // console.log('tribunal', tribunal);
    const authToken = this.authService.getToken();
    // // console.log('token', authToken)
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);

    let urlBuilder = baseURL+this.getPath( tribunal );

    return this._http.get(urlBuilder, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));

    // if (tribunal === 'Supreme Court'){
    //   // // console.log('test');
    //   return this._http.get(baseURL + 'scdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'SUPREME COURT'){
    //   return this._http.get(baseURL + 'scdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MumbaiHC') {
    //   return this._http.get(baseURL + 'mhcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // }else if (tribunal === 'UttarakhandHC') {
    //   return this._http.get(baseURL + 'uttarakhandhcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // }else if (tribunal === 'TripuraHC') {
    //   return this._http.get(baseURL + 'tripurahcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'KarnatakaHC') {
    //   return this._http.get(baseURL + 'karnatakahcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'GujaratHC') {
    //   return this._http.get(baseURL + 'gujarathcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MpHC') {
    //   return this._http.get(baseURL + 'mphcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'DelhiHC') {
    //   return this._http.get(baseURL + 'delhihcdata', {headers: headers})
    //   .pipe(map(res => res), catchError(error => {
    //     return this.processHTTPMsgService.handleError(error);
    //   }));
    // } else if ( tribunal === 'AllahabadHC' ) {
    //   return this._http.get(baseURL + 'allahabadhcdata', {headers: headers})
    //   .pipe(map(res => res), catchError(error => {
    //     return this.processHTTPMsgService.handleError(error);
    //   }));
    // } else if ( tribunal === 'CalcuttaHC' ) {
    //   return this._http.get(baseURL + 'calcuttahcdata', {headers: headers})
    //   .pipe(map(res => res), catchError(error => {
    //     return this.processHTTPMsgService.handleError(error);
    //   }));
    // } else if ( tribunal === 'KeralaHC' ) {
    //   return this._http.get(baseURL + 'keralahcdata', {headers: headers})
    //   .pipe(map(res => res), catchError(error => {
    //     return this.processHTTPMsgService.handleError(error);
    //   }));
    // } else if ( tribunal === 'PunjabHC' ) {
    //   return this._http.get(baseURL + 'punjabhcdata', {headers: headers})
    //   .pipe(map(res => res), catchError(error => {
    //     return this.processHTTPMsgService.handleError(error);
    //   }));
    // } else if (tribunal === 'ATFP') {
    //   return this._http.get(baseURL + 'atfpdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'BIFR') {
    //   return this._http.get(baseURL + 'bifrdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'CAT') {
    //   return this._http.get(baseURL + 'catdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'CESTAT') {
    //   return this._http.get(baseURL + 'cestatdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'DRT') {
    //   return this._http.get(baseURL + 'drtdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'IPAB') {
    //   return this._http.get(baseURL + 'ipabdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'ITAT') {
    //   return this._http.get(baseURL + 'itatdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MHRERA') {
    //   return this._http.get(baseURL + 'mahareradata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MAT') {
    //   return this._http.get(baseURL + 'matdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MCDRC') {
    //   return this._http.get(baseURL + 'mcdrcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MILC') {
    //   return this._http.get(baseURL + 'milcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MACT') {
    //   return this._http.get(baseURL + 'mactdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MSCA') {
    //   return this._http.get(baseURL + 'mscadata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'MST') {
    //   return this._http.get(baseURL + 'mstdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'NCDRC') {
    //   return this._http.get(baseURL + 'ncdrcdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'NCLAT') {
    //   return this._http.get(baseURL + 'nclatdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'PNGRB') {
    //   return this._http.get(baseURL + 'pngdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'RCT') {
    //   return this._http.get(baseURL + 'rctdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // } else if (tribunal === 'SAT') {
    //   return this._http.get(baseURL + 'satdata', {headers: headers})
    //     .pipe(map(res => res), catchError(error => {
    //       return this.processHTTPMsgService.handleError(error);
    //     }));
    // }
  }

  getSingleJudgementByCitation(citation: string, tribunal: string): Observable<any> {
    /**
 * Dharmil
 */
console.log("getSingleJudgmentsByCitation()");
// =========================================================================
    const authToken = this.authService.getToken();
    // // console.log('token', authToken)
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);

    let urlBuilder = baseURL+this.getPath( tribunal )+'?Citation='+citation+'&tribunal='+tribunal;

    console.log("UrlBuilder :",urlBuilder);


    if (tribunal === 'Supreme Court'){
      // this._http.get(baseURL + 'escdata?Citation="' + citation + '"&tribunal="' + tribunal + '"', {headers: headers})
      return this._http.get(baseURL + 'scdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'SUPREME COURT') {
      return this._http.get(baseURL + 'scdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
    else if (tribunal === 'MumbaiHC') {
      return this._http.get(baseURL + 'mhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'UttarakhandHC') {
      console.log("If-else URL : ");
      console.log(baseURL + 'uttarakhandhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers});
      return this._http.get(baseURL + 'uttarakhandhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'HimachalPradeshHC') {
      console.log("If-else URL : ");
      console.log(baseURL + 'himachalpradeshhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers});
      return this._http.get(baseURL + 'himachalpradeshhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'TripuraHC') {
      console.log("actual URL : ");
      console.log(baseURL + 'tripurahcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers});
      return this._http.get(baseURL + 'tripurahcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'MadrasHC') {
      console.log("actual URL : ");
      console.log(baseURL + 'madrashcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers});
      return this._http.get(baseURL + 'madrashcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'RajasthanHC') {
      console.log("actual URL : ");
      console.log(baseURL + 'rajasthanhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers});
      return this._http.get(baseURL + 'rajasthanhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'KarnatakaHC') {
      return this._http.get(baseURL + 'karnatakahcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'GujaratHC') {
      return this._http.get(baseURL + 'gujarathcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MpHC') {
      return this._http.get(baseURL + 'mphcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }

    else if (tribunal === 'DelhiHC') {
      return this._http.get(baseURL + 'delhihcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'AllahabadHC' ) {
      return this._http.get(baseURL + 'allahabadhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'CalcuttaHC' ) {
      return this._http.get(baseURL + 'calcuttahcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'KeralaHC' ) {
      return this._http.get(baseURL + 'keralahcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'PunjabHC' ) {
      return this._http.get(baseURL + 'punjabhcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'OrrisaHC' ) {
      return this._http.get(baseURL + 'orrisahcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'ATFP') {
      return this._http.get(baseURL + 'atfpdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'BIFR') {
      return this._http.get(baseURL + 'bifrdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CAT') {
      return this._http.get(baseURL + 'catdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CESTAT') {
      return this._http.get(baseURL + 'cestatdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'DRT') {
      return this._http.get(baseURL + 'drtdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'IPAB') {
      return this._http.get(baseURL + 'ipabdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'ITAT') {
      return this._http.get(baseURL + 'itatdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MHRERA') {
      return this._http.get(baseURL + 'mahareradata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MAT') {
      return this._http.get(baseURL + 'matdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MCDRC') {
      return this._http.get(baseURL + 'mcdrcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MILC') {
      return this._http.get(baseURL + 'milcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MACT') {
      return this._http.get(baseURL + 'mactdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MSCA') {
      return this._http.get(baseURL + 'mscadata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MST') {
      return this._http.get(baseURL + 'mstdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCDRC') {
      return this._http.get(baseURL + 'ncdrcdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCLAT') {
      return this._http.get(baseURL + 'nclatdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'PNGRB') {
      return this._http.get(baseURL + 'pngdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'RCT') {
      return this._http.get(baseURL + 'rctdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'SAT') {
      return this._http.get(baseURL + 'satdata?Citation=' + citation + '&tribunal=' + tribunal, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
  }

  getSingleJudgementByCitationElastic(citation: string, tribunal: string): Observable<any> {
/**
 * Dharmil
 */
console.log("getJudgmentsByCitationElastic()");
// =========================================================================
    const authToken = this.authService.getToken();
    // // console.log('token', authToken)
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);
    let urlBuilder = baseURL + 'e'+this.getPath( tribunal )+'?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"';

    console.log('Url Builder :',urlBuilder);


    if (tribunal === 'Supreme Court') {
      return this._http.get(baseURL + 'escdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
    } else if (tribunal === 'SUPREME COURT') {
      return this._http.get(baseURL + 'escdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
    } else if (tribunal === 'MumbaiHC') {
      return this._http.get(baseURL + 'emhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
     }else if (tribunal === 'UttarakhandHC') {
       console.log("Inside Uttarakhand URL: ",baseURL + 'euttarakhandhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"');
       return this._http.get(baseURL + 'euttarakhandhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
         .pipe(map(res => res), catchError(error => {
           return this.processHTTPMsgService.handleError(error);
         }));
      }else if (tribunal === 'HimachalPradeshHC') {
        console.log("Inside HimachalPradesh URL: ",baseURL + 'ehimachalpradeshhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"');
        return this._http.get(baseURL + 'ehimachalpradeshhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
          .pipe(map(res => res), catchError(error => {
            return this.processHTTPMsgService.handleError(error);
          }));
       }else if (tribunal === 'OrrisaHC') {
        console.log("Inside Orrisa URL: ",baseURL + 'ehimachalpradeshhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"');
        return this._http.get(baseURL + 'eorrisahcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
          .pipe(map(res => res), catchError(error => {
            return this.processHTTPMsgService.handleError(error);
          }));
       }else if (tribunal === 'TripuraHC') {
        //console.log(baseURL + 'etripurahcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"');
        return this._http.get(baseURL + 'etripurahcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
          .pipe(map(res => res), catchError(error => {
            return this.processHTTPMsgService.handleError(error);
          }));
       }else if (tribunal === 'MadrasHC') {
        //console.log(baseURL + 'emadrashcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"');
        return this._http.get(baseURL + 'emadrashcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
          .pipe(map(res => res), catchError(error => {
            return this.processHTTPMsgService.handleError(error);
          }));
       }else if (tribunal === 'RajasthanHC') {
        //console.log(baseURL + 'etripurahcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"');
        return this._http.get(baseURL + 'erajasthanhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
          .pipe(map(res => res), catchError(error => {
            return this.processHTTPMsgService.handleError(error);
          }));
       }else if (tribunal === 'GujaratHC') {
      return this._http.get(baseURL + 'egujarathcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'KarnatakaHC') {
      return this._http.get(baseURL + 'ekarnatakahcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MpHC') {
      return this._http.get(baseURL + 'emphcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'DelhiHC') {
      return this._http.get(baseURL + 'edelhihcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'AllahabadHC' ) {
      return this._http.get(baseURL + 'eallahabadhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'CalcuttaHC' ) {
      return this._http.get(baseURL + 'ecalcuttahcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'KeralaHC' ) {
      return this._http.get(baseURL + 'ekeralahcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'PunjabHC' ) {
      return this._http.get(baseURL + 'epunjabhcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
    else if (tribunal === 'ATFP') {
      return this._http.get(baseURL + 'eatfpdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'BIFR') {
      return this._http.get(baseURL + 'ebifrdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CAT') {
      return this._http.get(baseURL + 'ecatdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CESTAT') {
      return this._http.get(baseURL + 'ecestatdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'DRT') {
      return this._http.get(baseURL + 'edrtdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'IPAB') {
      return this._http.get(baseURL + 'eipabdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'ITAT') {
      return this._http.get(baseURL + 'eitatdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MHRERA') {
      return this._http.get(baseURL + 'emahareradata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MAT') {
      return this._http.get(baseURL + 'ematdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MCDRC') {
      return this._http.get(baseURL + 'emcdrcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MILC') {
      return this._http.get(baseURL + 'emilcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MACT') {
      return this._http.get(baseURL + 'emactdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MSCA') {
      return this._http.get(baseURL + 'emscadata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MST') {
      return this._http.get(baseURL + 'emstdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCDRC') {
      return this._http.get(baseURL + 'encdrcdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCLAT') {
      return this._http.get(baseURL + 'enclatdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'PNGRB') {
      return this._http.get(baseURL + 'epngdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'RCT') {
      return this._http.get(baseURL + 'erctdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'SAT') {
      return this._http.get(baseURL + 'esatdata?q=Citation:"' + citation + '"%20AND%20tribunal:"' + tribunal + '"', {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
  }

  getSingleJudgementById(id: string, tribunal: string): Observable<any> {
/**
 * Dharmil
 */
console.log("getSingleJudgmentsById()");
// =========================================================================
    const authToken = this.authService.getToken();
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);
    let urlBuilder = baseURL+this.getPath( tribunal )+'/'+id;
    return this._http.get(urlBuilder, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
  }

  updateSingleJudgementById(id: string, tribunal: string, judgement): Observable<any> {
/**
 * Dharmil
 */
console.log("update MumHc",id);
// =========================================================================
    // // console.log('servicejud', judgement);
    const authToken = this.authService.getToken();
    // // console.log('token', authToken)
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);

    if (tribunal === 'Supreme Court') {
      return this._http.put(baseURL + 'scdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'SUPREME COURT') {
      return this._http.put(baseURL + 'scdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'UttarakhandHC') {
      return this._http.put(baseURL + 'uttarakhandhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'HimachalPradeshHC') {
      return this._http.put(baseURL + 'himachalpradeshhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'OrrisaHC') {
      return this._http.put(baseURL + 'orrisahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'TripuraHC') {
      return this._http.put(baseURL + 'tripurahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'MadrasHC') {
      return this._http.put(baseURL + 'madrashcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'RajasthanHC') {
      return this._http.put(baseURL + 'rajasthanhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MumbaiHC') {
      console.log("in edit mumhc", id, judgement)
      return this._http.put(baseURL + 'mhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'GujaratHC') {
      return this._http.put(baseURL + 'gujarathcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'KarnatakaHC') {
      return this._http.put(baseURL + 'karnatakahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MpHC') {
      return this._http.put(baseURL + 'mphcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'DelhiHC') {
      return this._http.put(baseURL + 'delhihcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'AllahabadHC' ) {
      return this._http.put(baseURL + 'allahabadhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'CalcuttaHC' ) {
      return this._http.put(baseURL + 'calcuttahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'PunjabHC' ) {
      return this._http.put(baseURL + 'punjabhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'KeralaHC' ) {
      return this._http.put(baseURL + 'keralahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
    else if (tribunal === 'ATFP') {
      return this._http.put(baseURL + 'atfpdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'BIFR') {
      return this._http.put(baseURL + 'bifrdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CAT') {
      return this._http.put(baseURL + 'catdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CESTAT') {
      return this._http.put(baseURL + 'cestatdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'DRT') {
      return this._http.put(baseURL + 'drtdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'IPAB') {
      return this._http.put(baseURL + 'ipabdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'ITAT') {
      return this._http.put(baseURL + 'itatdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MHRERA') {
      return this._http.put(baseURL + 'mahareradata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MAT') {
      return this._http.put(baseURL + 'matdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MCDRC') {
      return this._http.put(baseURL + 'mcdrcdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MILC') {
      return this._http.put(baseURL + 'milcdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MACT') {
      return this._http.put(baseURL + 'mactdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MSCA') {
      return this._http.put(baseURL + 'mscadata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MST') {
      return this._http.put(baseURL + 'mstdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCDRC') {
      return this._http.put(baseURL + 'ncdrcdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCLAT') {
      return this._http.put(baseURL + 'nclatdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'PNGRB') {
      return this._http.put(baseURL + 'pngdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'RCT') {
      return this._http.put(baseURL + 'rctdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'SAT') {
      return this._http.put(baseURL + 'satdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
  }

  updateSingleJudgementByElasticId(id: string, tribunal: string, judgement): Observable<any> {
/**
 * Dharmil
 */
console.log("updateSingleJudgmentByElasticId()");
// =========================================================================
     console.log('judgment', judgement);
     console.log('tribunal', tribunal);
     console.log('id',id)
    const authToken = this.authService.getToken();
    // // console.log('token', authToken)
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);

    if (tribunal === 'Supreme Court') {
      return this._http.put(baseURL + 'escdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'Supreme Court') {
      return this._http.put(baseURL + 'escdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MumbaiHC') {
      return this._http.put(baseURL + 'emhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'UttarakhandHC') {
      console.log('ID : ',id);
      console.log('Judgement : ',judgement)
      return this._http.put(baseURL + 'euttarakhandhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'HimachalPradeshHC') {
      console.log('ID : ',id);
      console.log('Judgement : ',judgement)
      return this._http.put(baseURL + 'ehimachalpradeshhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'OrrisaHC') {
      console.log('ID : ',id);
      console.log('Judgement : ',judgement)
      return this._http.put(baseURL + 'eorrisahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'TripuraHC') {
      console.log('ID : ',id);
      console.log('Judgement : ',judgement)
      return this._http.put(baseURL + 'etripurahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'MadrasHC') {
      console.log('ID : ',id);
      console.log('Judgement : ',judgement)
      return this._http.put(baseURL + 'emadrashcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'RajasthanHC') {
      console.log('ID : ',id);
      console.log('Judgement : ',judgement)
      return this._http.put(baseURL + 'erajasthanhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'GujaratHC') {
      return this._http.put(baseURL + 'egujarathcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'KarnatakaHC') {
      return this._http.put(baseURL + 'ekarnatakahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MpHC') {
    return this._http.put(baseURL + 'emphcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'DelhiHC' ) {
      return this._http.put(baseURL + 'edelhihcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'AllahabadHC' ) {
      return this._http.put(baseURL + 'eallahabadhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'CalcuttaHC' ) {
      return this._http.put(baseURL + 'ecalcuttahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'KeralaHC' ) {
      return this._http.put(baseURL + 'ekeralahcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'PunjabHC' ) {
      return this._http.put(baseURL + 'epunjabhcdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
    else if (tribunal === 'ATFP') {
      return this._http.put(baseURL + 'eatfpdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'BIFR') {
      return this._http.put(baseURL + 'ebifrdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CAT') {
      return this._http.put(baseURL + 'ecatdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'CESTAT') {
      return this._http.put(baseURL + 'ecestatdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'DRT') {
      return this._http.put(baseURL + 'edrtdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'IPAB') {
      return this._http.put(baseURL + 'eipabdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'ITAT') {
      return this._http.put(baseURL + 'eitatdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MHRERA') {
      return this._http.put(baseURL + 'emahareradata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MAT') {
      return this._http.put(baseURL + 'ematdata/' + id, judgement, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MCDRC') {
      return this._http.put(baseURL + 'emcdrcdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MILC') {
      return this._http.put(baseURL + 'emilcdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MACT') {
      return this._http.put(baseURL + 'emactdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MSCA') {
      return this._http.put(baseURL + 'emscadata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MST') {
      return this._http.put(baseURL + 'emstdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCDRC') {
      return this._http.put(baseURL + 'encdrcdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'NCLAT') {
      return this._http.put(baseURL + 'enclatdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'PNGRB') {
      return this._http.put(baseURL + 'epngdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'RCT') {
      return this._http.put(baseURL + 'erctdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'SAT') {
      return this._http.put(baseURL + 'esatdata/' + id, judgement,  {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }
  }

  addSingleJudgement(judgement, tribunal: string): Observable<any> {
    /**
 * Dharmil
 */
console.log("addSingleJudgment()");
// =========================================================================
    const authToken = this.authService.getToken(); // Authorization Token
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    let headers = new HttpHeaders();

    headers.append('Authorization', 'bearer ' + actual);

    let urlBuilder = baseURL+this.getPath( tribunal )+'/'; // Validates and Builds Url
     console.log(urlBuilder);
     return this._http.post(urlBuilder,judgement,{headers: headers})
     .pipe(map(res => res), catchError(error => {
       return this.processHTTPMsgService.handleError(error);
    }));
  }

  deleteJudgementById(id: string, tribunal: string): Observable<any> {
    /**
 * Dharmil
 */
console.log("deleteJudgmentById()");
// =========================================================================
    const authToken = this.authService.getToken();
    // // console.log('token', authToken)
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);


    if (tribunal === 'Supreme Court'){
      return this._http.delete(baseURL + 'scdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MumbaiHC') {
      return this._http.delete(baseURL + 'mhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'GujaratHC') {
      return this._http.delete(baseURL + 'gujarathcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'KarnatakaHC') {
      return this._http.delete(baseURL + 'karnatakahcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'RajasthanHC') {
      return this._http.delete(baseURL + 'rajasthanhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MpHC') {
      return this._http.delete(baseURL + 'mphcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'DelhiHC' ) {
      return this._http.delete(baseURL + 'delhihcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'AllahabadHC' ) {
      return this._http.delete(baseURL + 'allahabadhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'CalcuttaHC' ) {
      return this._http.delete(baseURL + 'calcuttahcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'KeralaHC' ) {
      return this._http.delete(baseURL + 'keralahcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'PunjabHC' ) {
      return this._http.delete(baseURL + 'punjabhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }

  }

  deleteJudgementByElasticId(id: string, tribunal: string): Observable<any> {
    /**
 * Dharmil
 */
console.log("deleteJudgmentByElasticId()");
// =========================================================================
    const authToken = this.authService.getToken();
    // // console.log('token', authToken)
    //trim first character
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    var actual = myString.slice(0, -1);
    // console.log(authToken);
    let headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);


    if (tribunal === 'Supreme Court'){
      return this._http.delete(baseURL + 'escdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MumbaiHC') {
      return this._http.delete(baseURL + 'emhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'GujaratHC') {
      return this._http.delete(baseURL + 'egujarathcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'KarnatakaHC') {
      return this._http.delete(baseURL + 'ekarnatakahcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }else if (tribunal === 'RajasthanHC') {
      return this._http.delete(baseURL + 'erajasthanhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if (tribunal === 'MpHC') {
      return this._http.delete(baseURL + 'emphcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'DelhiHC' ) {
      return this._http.delete(baseURL + 'edelhihcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'AllahabadHC' ) {
      return this._http.delete(baseURL + 'eallahabadhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'CalcuttaHC' ) {
      return this._http.delete(baseURL + 'ecalcuttahcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'KeralaHC' ) {
      return this._http.delete(baseURL + 'ekeralahcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    } else if ( tribunal === 'PunjabHC' ) {
      return this._http.delete(baseURL + 'epunjabhcdata/' + id, {headers: headers})
        .pipe(map(res => res), catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        }));
    }

  }

  generateHTML(data): Observable<any> {
    /**
 * Dharmil
 */
console.log("generateHTML()");
console.log(data);
// =========================================================================
    const authToken = this.authService.getToken();
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    const actual = myString.slice(0, -1);
    // console.log(authToken);
    const headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);

    return this._http.post('https://genhtml.nearlaw.com/api/generateHTML', data, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  search(query: string): Observable<any> {
    /**
 * Dharmil
 */
console.log("search()");
// =========================================================================
    const authToken = this.authService.getToken();
    let myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    //trim last character
    const actual = myString.slice(0, -1);
    // // console.log(authToken);
    const headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + actual);
    console.log("Search-query :",query);
    return this._http.get(baseURL + 'search?q=' + query, {headers: headers})
    .pipe(map(res => res), catchError(error => {
      return this.processHTTPMsgService.handleError(error);
    }));
  }

  getSingleJudg(query: string): Observable<any> {
    /**
 * Dharmil
 */
console.log("getSingleJudg()");
// =========================================================================
    const authToken = this.authService.getToken().substr(1).slice(0, -1);
    // console.log(authToken);

    const headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + authToken);
    console.log(query);
    return this._http.get(baseURL + 'search-judgments?q=' + query, {headers: headers})
    .pipe(map(res => res), catchError(error => {
      return this.processHTTPMsgService.handleError(error);
    }));
  }

  uploadFile(file: File, tribunal: string): Observable<any> {
    /**
 * Dharmil
 */
console.log("upload()");
// =========================================================================
    const authToken = this.authService.getToken().substr(1).slice(0, -1);
    // console.log(authToken);
    // console.log(file);
    console.log('trib', tribunal);

    const headers = new HttpHeaders();
    headers.append('Authorization', 'bearer ' + authToken);

    const uploadData: FormData = new FormData();
    uploadData.append('file', file, file.name);

    if ( tribunal === 'Supreme Court' ) {
      return this._http.post(baseURL + 'scupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'MumbaiHC' ) {
      return this._http.post(baseURL + 'mumbaiHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'DelhiHC' ) {
      return this._http.post(baseURL + 'delhiHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    }else if ( tribunal === 'HimachalPradeshHC' ) {
      return this._http.post(baseURL + 'himachalpradeshHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'UttarakhandHC' ) {
      return this._http.post(baseURL + 'uttarakhandHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'TripuraHC' ) {
      return this._http.post(baseURL + 'tripuraHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'RajasthanHC' ) {
      return this._http.post(baseURL + 'rajasthanHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'MadrasHC' ) {
      return this._http.post(baseURL + 'madrasHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'MpHC' ) {
      return this._http.post(baseURL + 'mpHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'GujaratHC' ) {
      return this._http.post(baseURL + 'gujaratHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'KarnatakaHC' ) {
      return this._http.post(baseURL + 'karnatakaHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    }else if ( tribunal === 'OrrisaHC' ) {
      return this._http.post(baseURL + 'orrisaHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'AllahabadHC' ) {
      return this._http.post(baseURL + 'allahabadHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'CalcuttaHC' ) {
      return this._http.post(baseURL + 'calcuttaHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'KeralaHC' ) {
      return this._http.post(baseURL + 'keralaHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    } else if ( tribunal === 'PunjabHC' ) {
      return this._http.post(baseURL + 'punjabHCupload', uploadData, {headers: headers})
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }) );
    }

  }
}
