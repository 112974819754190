import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { User } from '../shared/user';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  user: User;

  formErrors = {
    'username': '',
    'password': ''
  };
  
  validationMessages = {
    'username': {
      'required': 'Please enter Username.',
      'minlength': 'Username should be atleast 2 characters',
      'maxlength': 'Username should not exceed more than 20 characters'
    },
    'password': {
      'required': 'Password is required.',
      'minlength': 'Password must be of atleast 8 characters',
      'maxlength': 'Password should not exceed more than 25 characters'
    },
  };

  constructor(
    private fb: FormBuilder, 
    private router: Router, 
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) { 
      this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.signupForm = this.fb.group({
      // username: ['', Validators.required, Validators.minLength(2), Validators.maxLength(20)],
      // password: ['', Validators.required, Validators.minLength(8), Validators.maxLength(25)]
      username: '',
      password: ''
    });

    this.signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.signupForm) { return; }
    const form = this.signupForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  onSubmit() {
    this.user = this.signupForm.value;
    console.log(this.user);
    // this.authService.signUp(this.user).subscribe(res => {
    //   console.log('status', res);
    //   if (res.success === true) {
    //     this.router.navigate(['/home']).then(() => {
    //       this.snackBar.open("Successfully signed up!", "Ok", {
    //         duration: 2000
    //       });
    //     });
    //   } else if (res.success === false && res.err !== 'User already exists') {
    //     this.router.navigate(['/signup']).then(() => {
    //       this.snackBar.open("Sign up failed, Kindly sign up again!", "Ok", {
    //         duration: 3000
    //       });
    //     });
    //   } else if (res.success === false && res.err === 'User already exists') {
    //     this.router.navigate(['/login']).then(() => {
    //       this.snackBar.open("User already exists, kindly login!", "Ok", {
    //         duration: 3000
    //       });
    //     });
    //   }
    // });
  }

}
