export const lawTopics = [
    { value: 'Select one' },
    {value: 'Accident Claims' },
    {value: 'Administrative Law' },
    {value: 'Arbitration & Mediation'},
    {value: 'Armed Forces'},
    {value: 'Banking & Finance'},
    {value: 'Company Law'},
    {value: 'Civil & Criminal'},
    {value: 'Competition/ Monopoly'},
    {value: 'Contracts'},
    {value: 'Constitution of India'},
    {value: 'Criminal'},
    {value: 'Deeds & Conveyance'},
    {value: 'Environmental Law'},
    {value: 'Family'},
    {value: 'Goods & Services'},
    {value: 'Immigration'},
    {value: 'Information Technology'},
    {value: 'Insurance'},
    {value: 'Intellectual Property'},
    {value: 'International'},
    {value: 'Juvenile'},
    {value: 'Labour & Service'},
    {value: 'Law of Medicine'},
    {value: 'Law of Torts'},
    {value: 'Legal Process Outsourcing'},
    {value: 'Maritime'},
    {value: 'Media & Entertainment'},
    {value: 'Other Civil'},
    {value: 'Property & Real Estate'},
    {value: 'Public Interest Litigation'},
    {value: 'Sports'},
    {value: 'Taxation'},
    {value: 'Wills & Trusts'},
    {value: 'Women & Children'}
];
