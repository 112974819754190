import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { User } from '../shared/user';
import { baseURL } from '../shared/baseurl';
// import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  //user: {username: '', password: ''};
  user: User;
  errMess: string;
  isUser = false;
  otp = '';

  formErrors = {
    //'email': '',
    'username': '',
    'password': ''
  }

  validationMessages = {
    /*'email': {
      'required': 'Email is required.',
      'email': 'Email is not in valid format.'
    },*/
    'username': {
      'required': 'Please enter Username.'
    },
    'password': {
      'required': 'Please enter Password.'
    },
  };

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public snackBar: MatSnackBar
  ) { 
    this.createForm();
  }

  ngOnInit() {
  }

  createForm(){
    this.loginForm = this.fb.group({
      //email: ['', Validators.required, Validators.email],
      username: '',
      password: ''
    });

    this.loginForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.loginForm) { return; }
    const form = this.loginForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  onSubmit() {
    this.user = this.loginForm.value;
    // console.log('user', this.user);
    this.authService.getOtp(this.user).subscribe(res => {
      // console.log('status', res.msg);

      if (res.success === true) {
        console.log('yo');
        this.isUser = true;
        // this.authService.isAuthenticated = true;
        // this.authService.storeUserData(res.token, res.user, res.user._id);
        // this.router.navigate(['/home']).then(() => {
        //   this.snackBar.open("Welcome!", "Ok", {
        //     duration: 2000
        //   });
        // });
      } else if (res.success === false && res.msg === 'User not found') {
        // this.router.navigate(['/signup']).then(() => {
          this.snackBar.open("User does not exist!", "Ok", {
            duration: 2000
          });
        // });
      } else if (res.success === false && res.msg === 'Wrong password!') {
        // this.router.navigate(['/signup']).then(() => {
          this.snackBar.open("Password is incorrect!", "Ok", {
            duration: 2000
          });
        // });
      }
    });
  }

  login() {
    console.log('otp', this.otp);
    if ( this.otp !== '' ) {
      this.authService.logIn(this.user, this.otp).subscribe(data => {
        if ( data.success === true ) {
          this.authService.isAuthenticated = true;
          this.authService.storeUserData(data.token, data.user, data.user._id);
          this.router.navigate(['/home']).then(() => {
            this.snackBar.open('Welcome!', 'Ok', {
              duration: 2000
            });
          });
        } else if (data.success === false && data.msg === 'User not found') {
          // this.router.navigate(['/signup']).then(() => {
            this.snackBar.open('User does not exist!', 'Ok', {
              duration: 2000
            });
          // });
        } else if (data.success === false && data.msg === 'Wrong password!') {
          // this.router.navigate(['/signup']).then(() => {
            this.snackBar.open('Password is incorrect!', 'Ok', {
              duration: 2000
            });
          // });
        } else if ( data.success === false && data.msg === 'Wrong Otp' ) {
          const snackBar = this.snackBar.open('Wrong Otp', 'Resend Otp', {
            duration: 5000
          });

          snackBar.onAction().subscribe(() => {
            this.onSubmit();
          });
        }
      });
    } else {
      this.snackBar.open('Kindly enter OTP', 'OK', {
        duration: 5000
      });
    }
    
  }
}
