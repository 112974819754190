import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator, MatTable, MatSnackBar, MatProgressBar, MatTableDataSource, MatSort, MatDialog,
   MatDialogRef, MatAutocomplete, MatIcon, MatTextareaAutosize, MatTab, MatChipInputEvent, MatChipInput,
   MatChipList, MatSelectChange, MatTabChangeEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';

const moment = _moment;


import {COMMA, ENTER} from '@angular/cdk/keycodes';

import { AuthService } from '../services/auth.service';
import { JudgementService } from '../services/judgement.service';

import { User } from '../shared/user';
import { TrailUser } from '../shared/trialuser';
import { ATFP } from '../shared/atfp';
import { BIFR } from '../shared/BIFR';
import { Tribunal } from '../shared/tribunal';
import { baseURL } from '../shared/baseurl';
import { TribunalYear } from '../shared/tribunalyear';
import { lawTopics } from '../shared/lawtopics';
import { dateRangeList } from '../shared/dropdowns';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class HomeComponent implements OnInit {

  tribunals: Tribunal[] = [
    {
      value: 'Supreme Court',
      year: ['1945', '1946', '1947', '1948', '1949', '1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025'].reverse()
    },
    {
      value: 'SUPREME COURT',
      year: ['2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021', '2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MumbaiHC',
      year: ['1945', '1946', '1947', '1948', '1949', '1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'DelhiHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'KeralaHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'KarnatakaHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'RajasthanHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'GujaratHC',
      year: ['1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'PunjabHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'CalcuttaHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MadrasHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'TripuraHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'UttarakhandHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'HimachalPradeshHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'OrrisaHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MpHC',    // Madhya Pradesh
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'AllahabadHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'HyderabadHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'PatnaHC',
      year: ['1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'ATFP',
      year: ['2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'BIFR',
      year: ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'CAT',
      year: ['2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'CESTAT',
      year: ['2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'DRT',
      year: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'IPAB',
      year: ['2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'ITAT',
      year: ['2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MHRERA',
      year: ['2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MAT',
      year: ['2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MCDRC',
      year: ['2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MILC',
      year: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MACT',
      year: ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MSCA',
      year: ['2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'MST',
      year: ['2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'NCDRC',
      year: ['2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'NCLAT',
      year: ['2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'PNGRB',
      year: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'RCT',
      year: ['1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    },
    {
      value: 'SAT',
      year: ['2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019','2020','2021','2022','2023','2024','2025'].reverse()
    }
  ];

  scConfig: Object;
  MumbaiHCConfig: Object;
  MpHCConfig: Object;
  NCDRCConfig: Object;
  DelhiHCConfig: Object;
  AllahabadHCConfig: Object;


    // Added by Dharmil
    MadrasHCConfig: Object;
    UttarakhandHCConfig: Object;
    TripuraHCConfig: Object;
    RajasthanHCConfig: Object;
    OrrisaHCConfig: Object;


  trialUsers: TrailUser[];
  tribunal: Tribunal; //dropdown tribunal
  judgements: ATFP[];
  citations = []; //for storing citation from judgement object
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  selectedTribunal: Tribunal;
  selectedyear: string;
  filteredcitations = [];//for storing value of citation by year
  selectedCitation: string;
  judgement: ATFP;
  singlejudg: BIFR;
  resultjudg: BIFR;
  judgForm: BIFR; //Used in add judgements
  id: string;
  showResult = false;
  singlejudgcheck: BIFR;
  userType: string;
  tribunalYears = []; //used for displayin year in descending order

  isAdv_petitionerEditable: boolean = false;
  isAdv_respondentEditable: boolean = false;
  isCase_NoEditable: boolean = false;
  isCitationEditable = false;
  isEquiCitEditable = false;
  isCourtEditable = false;
  isTribunalEditable = false;
  isDateEditable = false;
  isHeadNote_keywordsEditable = false;
  isheadnoteEditable = false;
  isJudge_nameEditable = false;
  isPetitionerEditable = false;
  isRespondentEditable = false;
  iscases_citedEditable = false;
  isdecisionEditable = false;
  isfileurlEditable = false;
  isfile_urlEditable = false;
  isJudgementEditable = false;
  isDistrictEditable = false;
  isorderEditable = false;
  islatest_importantEditable = false;
  isSectionEditable = false;
  isRuleEditable = false;
  isArticleEditable = false;
  isHighcourtEditable = false;
  isHighcourtDecisionEditable = false;
  isActStatueNameEditable = false;
  isHeadlineEditable = false;
  isImportantEditable = false;
  isOftenCitedEditable = false;
  isLandmarkEditable = false;
  isElasticIdEditable = false;
  isLawTopicEditable = false;

  isSubmitDisable = false;

  Adv_petitioner: string;
  Adv_respondent: string;
  Case_No: string;
  Citation: string;
  Court: string;
  Tribunal: string;
  Date: string;
  // HeadNote_keywords: string;
  HeadNote: string;
  Judge_name: string;
  Petitioner: string;
  Respondent: string;
  cases_cited: string;
  decision: string;
  fileurl: string;
  file_url: string;
  jUdgement: string;
  District: string;
  order: string;
  latest_important: boolean;
  section: string;
  article: string;
  rule: string;
  act_statue_name: string;
  Highcourt: string;
  hc_decision: string;
  headline: string;
  important: boolean;
  often_cited: boolean;
  landmark: boolean;
  elastic_id: string;
  selectedCitationDate = '';



  latest_impSelect = [
    { value: true, viewValue: 'Yes' },
    { value: false, viewValue: 'No' }
  ];

  progressbarflag = false;
  addFormProgressBarflag = false;
  showEditPB = false;

  showYear = false;       //it is used to hide or show year input
  showCitation = false;   //it is used to hide or show citation input
  isFormValid = false;

  addJudgementForm: FormGroup;
  CitationFormField = new FormControl(Validators.required);

  matcher = new MyErrorStateMatcher();

  sclink: string;
  sclink1: string;
  elasticId: string;
  eId:string;

  public isMarkerChecked: boolean;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  equiCit = '';
  equiCits = [];
  lawTopics = lawTopics;
  lawTopicsValue: string[] = [];
  lawTopicValueEdit = [];

  petitionerName = '';
  respondentName = '';
  tribunalPartySearch = '';
  citationList: string[] = [];
  judgmentList: BIFR[] = [];
  showSelectCitaion = false;
  selectedCitation1 = '';
  elasticIDList: any[] = [];

  selectedTribunalDate = '';
  dateRangeList = dateRangeList;
  selectedDateRange = '';
  dateSearchResultList: BIFR[] = [];
  showDateSearchPB = false;

  dateFrom: Date = null;
  dateTo: Date = null;
  maxDate: Date = new Date(Date.now());
  dateToControl = new FormControl(moment());
  dateFromControl = new FormControl(moment());

  showPB = false;

  uploadFile: File = null;
  uploadDisabled = true;
  tabTribunal = 'Supreme Court';

  isIdEditable = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private judgementService: JudgementService,
    public snackBar: MatSnackBar
  ) {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.createJudgementForm();
  }

  ngOnInit() {
    // var dt = new Date();
    // // // console.log('date', dt);
    var type = localStorage.getItem('type');
    var a = type.substr(1);
    this.userType = a.slice(0, -1);
    console.log('type => ', this.userType);
    window.addEventListener('scroll', this.scroll, true);
    var token = this.authService.getToken();
    this.scConfig = {
      formatsAllowed: ".html,.pdf",
      uploadAPI: {
        url: baseURL + "scupload",
        headers: {

          "Authorization" : `Bearer ${token}`
        }
      }
    };
    this.MumbaiHCConfig = {
      formatsAllowed: ".html,.pdf",
      uploadAPI: {
        url: baseURL + "mumbaiHCupload",
        headers: {

          "Authorization" : `Bearer ${token}`
        }
      }
    };
    this.MpHCConfig = {
      formatsAllowed: ".html,.pdf",
      uploadAPI: {
        url: baseURL + "mpHCupload",
        headers: {

          "Authorization" : `Bearer ${token}`
        }
      }
    };
    this.NCDRCConfig = {
      formatsAllowed: ".html,.pdf",
      uploadAPI: {
        url: baseURL + "NCDRCupload",
        headers: {

          "Authorization" : `Bearer ${token}`
        }
      }
    };
    this.DelhiHCConfig = {
      formatsAllowed: ".html,.pdf",
      uploadAPI: {
        url: baseURL + "delhiHCupload",
        headers: {

          "Authorization" : `Bearer ${token}`
        }
      }
    };
    this.AllahabadHCConfig = {
      formatsAllowed: ".html,.pdf",
      uploadAPI: {
        url: baseURL + "allahabadHCupload",
        headers: {

          "Authorization" : `Bearer ${token}`
        }
      }
    };

    //Added by Dharmil
    this.MadrasHCConfig = {
      formatsAllowed: '.html,.pdf',
      uploadAPI: {
        url: baseURL + 'madrasHCupload',
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      }
    };
    this.UttarakhandHCConfig = {
      formatsAllowed: '.html,.pdf',
      uploadAPI: {
        url: baseURL + 'uttarakhandHCupload',
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      }
    };
    this.TripuraHCConfig = {
      formatsAllowed: '.html,.pdf',
      uploadAPI: {
        url: baseURL + 'tripuraHCupload',
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      }
    };
    this.RajasthanHCConfig = {
      formatsAllowed: '.html,.pdf',
      uploadAPI: {
        url: baseURL + 'rajasthanHCupload',
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      }
    };
    this.OrrisaHCConfig = {
      formatsAllowed: '.html,.pdf',
      uploadAPI: {
        url: baseURL + 'OrrisaHCupload',
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      }
    };
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll() {
    if ( document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ) {
      document.getElementById("scrolltopbtn").style.display = "block";
    } else {
      document.getElementById("scrolltopbtn").style.display = "none";
    }
  }

  scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  onSearchTribunal() {
    this.progressbarflag = true;
    this.citations = [];
    //console.log('tribunal', this.selectedTribunal.value);
    this.judgementService.getJudgmentsByTribunal(this.selectedTribunal.value).subscribe(judgements => {
      this.judgements = judgements;
      this.progressbarflag = false;
      this.showYear = true;
      console.log('judgements', this.judgements);
      this.judgements.forEach(judgement => {
        // // // console.log('caseno', judgement.Case_No);
        this.citations.push(judgement.Citation);

      });
      // // // console.log('Citations:', this.citations);
    });

  }

  onSearchYear() {
    let yearReg = new RegExp(`^${this.selectedyear}`);
    // console.log('year', this.selectedyear);
    this.filteredcitations = this.citations.filter(option => yearReg.test(option)).sort();
    console.log('filteryear', this.filteredcitations);
    this.showCitation = true;
  }

  private _filter(value: string): string[] {
    // // // console.log('value', value);
    // // // console.log('test', this.filteredcitations);
    const filterValue = value.toLowerCase();
    return this.filteredcitations
      .filter(option => option.toLowerCase().includes(filterValue));
    //   .sort((a, b) => {
    //   if (a > b)
    //     return -1;
    //   else if ( a < b)
    //     return 1;
    //   else
    //     return 0;
    // });
  }

  onSearchCitation() {
    this.singlejudgcheck = null;
    this.showResult = true;
    console.log('citation :', this.selectedCitation);
    console.log('tribunal :', this.selectedTribunal.value);
    // this.singlejudg.old_citation = this.selectedCitation;
    this.judgementService.getSingleJudgementByCitation(this.selectedCitation, this.selectedTribunal.value).subscribe(judgement => {
      this.judgement = judgement;
      // if (this.judgement === undefined) {
      //   this.snackBar.open('Error occured, kindy search again', 'OK', {
      //     duration: 3000
      //   });
      // }
      console.log('resp', judgement);

      if (judgement.length === 0) {
        this.snackBar.open('No data found', 'OK', {
          duration: 5000
        });
      }

      this.judgementService.getSingleJudgementById(this.judgement[0]._id, this.selectedTribunal.value).subscribe(response => {
        this.singlejudg = response;
        this.singlejudgcheck = this.singlejudg;
        console.log('singlejudg', this.singlejudg);
        this.isMarkerChecked = this.singlejudg.Marked;
        // // console.log('ismarked', this.isMarkerChecked);
        // // // console.log('headnote', this.singlejudg.HeadNote);
        if (this.singlejudg.file_url !== undefined) {
          this.sclink = 'https://nearlaw.com/PDF/' + this.singlejudg.file_url;
          const check = this.sclink.substr(this.sclink.length - 4);
          // // console.log('sclink1', check);
          if ( check === 'html' ) {
            const temp = this.sclink.slice(0, -4);
            // // console.log('temp', temp);
            this.sclink1 = temp + 'pdf';
          }
          // // console.log('link', this.sclink1);
          // // // console.log('link', this.sclink);
          // // // console.log('url', this.singlejudg.file_url);
        } else if (this.singlejudg.fileurl !== undefined) {
          this.sclink = 'https://nearlaw.com/PDF/' + this.singlejudg.fileurl;
          const check = this.sclink.substr(this.sclink.length - 4);
          // // console.log('sclink1', check);
          if ( check === 'html' ) {
            const temp = this.sclink.slice(0, -4);
            // // console.log('temp', temp);
            this.sclink1 = temp + 'pdf';
          }
          // // console.log('link', this.sclink1);
          // // // console.log('link', this.sclink);
          // // // console.log('url', this.singlejudg.fileurl);
        }

      });
    });
    this.judgementService.getSingleJudgementByCitationElastic(this.selectedCitation, this.selectedTribunal.value ).subscribe(
      judgement => {
      if(judgement.hits.hits.length <= 0 ){
        this.elasticId = 'No Id';
      }else{
        this.elasticId = judgement.hits.hits[0]._id;
      }
      console.log('elastic Id', this.elasticId);
    });

  }



  editlatest_important() {
    this.islatest_importantEditable = true;
    this.latest_important = this.singlejudg.latest_important;
  }

  editId(){
    this.isIdEditable = true;
  }

  saveId() {
    this.isIdEditable = false;
  }

  cancelId() {
    this.isIdEditable = false;
  }

  savelatest_important() {
    this.islatest_importantEditable = false;
    // // console.log('latestcheck', this.latest_important);
    this.singlejudg.latest_important = this.latest_important;
    // // console.log('singleJud', this.singlejudg);
    // // // console.log('latest_imp', this.singlejudg.latest_important);
  }

  cancellatest_important() {
    this.islatest_importantEditable = false;
  }

  editImportant() {
    this.isImportantEditable = true;
    this.important = this.singlejudg.important;
  }

  saveImportant() {
    this.isImportantEditable = false;
    this.singlejudg.important = this.important;
  }

  cancelImportant() {
    this.isImportantEditable = false;
  }

  editOftenCited() {
    this.isOftenCitedEditable = true;
  }

  saveOftenCited() {
    this.isOftenCitedEditable = false;
    this.singlejudg.often_cited = this.often_cited;
  }

  cancelOftenCited() {
    this.isOftenCitedEditable = false;
  }

  editLandmark() {
    this.isLandmarkEditable = true;
  }

  saveLandmark() {
    this.isLandmarkEditable = false;
    this.singlejudg.landmark = this.landmark;
  }

  cancelLandmark() {
    this.isLandmarkEditable = false;
  }

  // onSearchComboCitation() {

  // }

  editAdv_petitioner() {
    this.isAdv_petitionerEditable = true;
    this.Adv_petitioner = this.singlejudg.Adv_petitioner;
  }

  saveAdv_petitioner(value: string) {
    this.isAdv_petitionerEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Adv_petitioner);
      if (this.Adv_petitioner === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Adv_petitioner !== '') {
        this.singlejudg.Adv_petitioner = this.Adv_petitioner;
        // // // console.log('adv_petitioner', this.singlejudg.Adv_petitioner);
      }
    } else if (value === '') {
      this.singlejudg.Adv_petitioner = this.Adv_petitioner;
      // // // console.log('adv_petitioner', this.singlejudg.Adv_petitioner);
    }
  }

  cancelAdv_petitioner() {
    this.isAdv_petitionerEditable = false;
  }

  editAdv_respondent() {
    this.isAdv_respondentEditable = true;
    this.Adv_respondent = this.singlejudg.Adv_respondent;
  }

  saveAdv_respondent(value: string) {
    this.isAdv_respondentEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Adv_respondent);
      if (this.Adv_respondent === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Adv_respondent !== '') {
        this.singlejudg.Adv_respondent = this.Adv_respondent;
        // // // console.log('Adv_respondent', this.singlejudg.Adv_respondent);
      }
    } else if (value === '') {
      this.singlejudg.Adv_respondent = this.Adv_respondent;
      // // // console.log('Adv_respondent', this.singlejudg.Adv_respondent);
    }
  }

  cancelAdv_respondent() {
    this.isAdv_respondentEditable = false;
  }

  editCase_No() {
    this.isCase_NoEditable = true;
    this.Case_No = this.singlejudg.Case_No;
  }

  saveCase_No(value: string) {
    this.isCase_NoEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Case_No);
      if (this.Case_No === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Case_No !== '') {
        this.singlejudg.Case_No = this.Case_No;
        // // // console.log('Case_No', this.singlejudg.Case_No);
      }
    } else if (value === '') {
      this.singlejudg.Case_No = this.Case_No;
      // // // console.log('Case_No', this.singlejudg.Case_No);
    }
  }

  cancelCase_No() {
    this.isCase_NoEditable = false;
  }

  editCitation() {
    this.isCitationEditable = true;
    this.Citation = this.singlejudg.Citation;
  }

  saveCitation(value: string) {
    this.isCitationEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Citation);
      if (this.Citation === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Citation !== '') {
        this.singlejudg.Citation = this.Citation;
        // // // console.log('Citation', this.singlejudg.Citation);
      }
    } else if (value === '') {
      this.singlejudg.Citation = this.Citation;
      // // // console.log('Citation', this.singlejudg.Citation);
    }
  }

  cancelCitation() {
    this.isCitationEditable = false;
  }

  editEquiCitation() {
    this.isEquiCitEditable = true;
    this.equiCits = this.singlejudg.equivalent_citation;
  }

  saveEquiCitation(value: string[]) {
    this.isEquiCitEditable = false;
    // // console.log('value', value);
    if ( value.length !== 0 ) {
      this.singlejudg.equivalent_citation = this.equiCits;
    }
    // // console.log('equicitation', this.singlejudg.equivalent_citation);
  }

  cancelEquiCitation() {
    this.isEquiCitEditable = false;
  }

  editLawTopic() {
    this.isLawTopicEditable = true;
    this.lawTopicValueEdit = this.singlejudg.law_topic;
  }

  saveLawTopic(value: string[]) {
    this.isLawTopicEditable = false;

    // // console.log('lawtopics', this.lawTopicValueEdit);
    // // // console.log('value', value);
    // this.singlejudg.law_topic = this.lawTopicValueEdit;
    // if ( this.lawTopicValueEdit.length !== 0 ) {
      this.singlejudg.law_topic = this.lawTopicValueEdit;
    // }
    // // console.log('lawtopic', this.singlejudg.law_topic);
  }

  cancelLawTopic() {
    this.isLawTopicEditable = false;
  }

  editCourt() {
    this.isCourtEditable = true;
    this.Court = this.singlejudg.Court;
  }

  saveCourt(value: string) {
    this.isCourtEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Court);
      if (this.Court === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Court !== '') {
        this.singlejudg.Court = this.Court;
        // // // console.log('Court', this.singlejudg.Court);
      }
    } else if (value === '') {
      this.singlejudg.Court = this.Court;
      // // // console.log('Court', this.singlejudg.Court);
    }
  }

  cancelCourt() {
    this.isCourtEditable = false;
  }

  editDistrict() {
    this.isDistrictEditable = true;
    this.District = this.singlejudg.District;
  }

  saveDistrict(value: string) {
    this.isDistrictEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.District);
      if (this.District === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.District !== '') {
        this.singlejudg.District = this.District;
        // // // console.log('District', this.singlejudg.District);
      }
    } else if (value === '') {
      this.singlejudg.District = this.District;
      // // // console.log('District', this.singlejudg.District);
    }
  }

  cancelDistrict() {
    this.isDistrictEditable = false;
  }

  editTribunal() {
    // // // console.log('yo');
    this.isTribunalEditable = true;
    this.Tribunal = this.singlejudg.tribunal;
  }

  saveTribunal(value: string) {
    this.isTribunalEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Tribunal);
      if (this.Tribunal === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Tribunal !== '') {
        this.singlejudg.tribunal = this.Tribunal;
        // // // console.log('Tribunal', this.singlejudg.tribunal);
      }
    } else if (value === '') {
      this.singlejudg.tribunal = this.Tribunal;
      // // // console.log('Tribunal', this.singlejudg.tribunal);
    }
  }

  cancelTribunal() {
    this.isTribunalEditable = false;
  }

  editDate() {
    this.isDateEditable = true;
    this.Date = this.singlejudg.Date;
  }

  saveDate(value: string) {
    this.isDateEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Date);
      if (this.Date === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Date !== '') {
        this.singlejudg.Date = this.Date;
        // // // console.log('Date', this.singlejudg.Date);
      }
    } else if (value === '') {
      this.singlejudg.Date = this.Date;
      // // // console.log('Date', this.singlejudg.Date);
    }
  }

  cancelDate() {
    this.isDateEditable = false;
  }

  // editHeadNote_keywords() {
  //   this.isHeadNote_keywordsEditable = true;
  //   this.HeadNote_keywords = this.singlejudg.HeadNote;
  // }

  // saveHeadNote_keywords(value: string) {
  //   this.isHeadNote_keywordsEditable = false;
  //   if(value !== '') {
  //     // // console.log('hi');
  //     // // console.log('test', this.HeadNote_keywords);
  //     if(this.HeadNote_keywords === '') {
  //       // // console.log('hi');
  //       this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
  //         duration: 3000
  //       });
  //     } else if (this.HeadNote_keywords !== '') {
  //       this.singlejudg.HeadNote = this.HeadNote_keywords;
  //       // // console.log('headnote', this.singlejudg.HeadNote_keywords);
  //     }
  //   } else if (value === '') {
  //     this.singlejudg.HeadNote = this.HeadNote_keywords;
  //     // // console.log('headnote', this.singlejudg.HeadNote_keywords);
  //   }
  // }

  // cancelHeadNote_keywords() {
  //   this.isHeadNote_keywordsEditable = false;
  // }

  editHeadline() {
    this.isHeadlineEditable = true;
    this.headline = this.singlejudg.headline;
  }

  saveHeadline(value: string) {
    this.isHeadlineEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.HeadNote);
      if (this.headline === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.headline !== '') {
        this.singlejudg.headline = this.headline;
        // // // console.log('headnote', this.singlejudg.HeadNote);
      }
    } else if (value === '') {
      this.singlejudg.headline = this.headline;
      // // // console.log('headnote', this.singlejudg.HeadNote);
    }
  }

  cancelHeadline() {
    this.isHeadlineEditable = false;
  }

  editheadnote() {
    this.isheadnoteEditable = true;
    this.HeadNote = this.singlejudg.HeadNote;
  }

  saveheadnote(value: string) {
    this.isheadnoteEditable = false;
    // if(value === undefined) {
    //   // // console.log('undefined');
    // }
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.HeadNote);
      if (this.HeadNote === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.HeadNote !== '') {
        this.singlejudg.HeadNote = this.HeadNote;
        // // // console.log('headnote', this.singlejudg.HeadNote);
      }
    } else if (value === '') {
      this.singlejudg.HeadNote = this.HeadNote;
      // // // console.log('headnote', this.singlejudg.HeadNote);
    }
  }

  cancelheadnote() {
    this.isheadnoteEditable = false;
  }

  editJudge_name() {
    this.isJudge_nameEditable = true;
    this.Judge_name = this.singlejudg.Judge_name;
  }

  saveJudge_name(value: string) {
    this.isJudge_nameEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Judge_name);
      if (this.Judge_name === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Judge_name !== '') {
        this.singlejudg.Judge_name = this.Judge_name;
        // // // console.log('Judge_name', this.singlejudg.Judge_name);
      }
    } else if (value === '') {
      this.singlejudg.Judge_name = this.Judge_name;
      // // // console.log('Judge_name', this.singlejudg.Judge_name);
    }
  }

  cancelJudge_name() {
    this.isJudge_nameEditable = false;
  }

  editPetitioner() {
    this.isPetitionerEditable = true;
    this.Petitioner = this.singlejudg.Petitioner;
  }

  savePetitioner(value: string) {
    this.isPetitionerEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Petitioner);
      if (this.Petitioner === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Petitioner !== '') {
        this.singlejudg.Petitioner = this.Petitioner;
        // // // console.log('Petitioner', this.singlejudg.Petitioner);
      }
    } else if (value === '') {
      this.singlejudg.Petitioner = this.Petitioner;
      // // // console.log('Petitioner', this.singlejudg.Petitioner);
    }
  }

  cancelPetitioner() {
    this.isPetitionerEditable = false;
  }

  editRespondent() {
    this.isRespondentEditable = true;
    this.Respondent = this.singlejudg.Respondent;
  }

  saveRespondent(value: string) {
    this.isRespondentEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.Respondent);
      if (this.Respondent === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Respondent !== '') {
        this.singlejudg.Respondent = this.Respondent;
        // // // console.log('Respondent', this.singlejudg.Respondent);
      }
    } else if (value === '') {
      this.singlejudg.Respondent = this.Respondent;
      // // // console.log('Respondent', this.singlejudg.Respondent);
    }
  }

  cancelRespondent() {
    this.isRespondentEditable = false;
  }

  editcases_cited() {
    this.iscases_citedEditable = true;
    this.cases_cited = this.singlejudg.cases_cited;
  }

  savecases_cited(value: string) {
    this.iscases_citedEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.cases_cited);
      if (this.cases_cited === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.cases_cited !== '') {
        this.singlejudg.cases_cited = this.cases_cited;
        // // // console.log('cases_cited', this.singlejudg.cases_cited);
      }
    } else if (value === '') {
      this.singlejudg.cases_cited = this.cases_cited;
      // // // console.log('cases_cited', this.singlejudg.cases_cited);
    }
  }

  cancelcases_cited() {
    this.iscases_citedEditable = false;
  }

  editdecision() {
    this.isdecisionEditable = true;
    this.decision = this.singlejudg.decision;
  }

  savedecision(value: string) {
    this.isdecisionEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.decision);
      if (this.decision === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.decision !== '') {
        this.singlejudg.decision = this.decision;
        // // // console.log('decision', this.singlejudg.decision);
      }
    } else if (value === '') {
      this.singlejudg.decision = this.decision;
      // // // console.log('decision', this.singlejudg.decision);
    }
  }

  canceldecision() {
    this.isdecisionEditable = false;
  }

  editfileurl() {
    this.isfileurlEditable = true;
    this.fileurl = this.singlejudg.fileurl;
  }

  savefileurl(value: string) {
    // // // console.log(value);
    this.isfileurlEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.fileurl);
      if (this.fileurl === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.fileurl !== '') {
        this.singlejudg.fileurl = this.fileurl;
        this.singlejudg.pdfurl = this.fileurl.replace('.html','.pdf')
        // // // console.log('fileurl', this.singlejudg.fileurl);
      }
    } else if (value === '') {
      this.singlejudg.fileurl = this.fileurl;
      // // // console.log('fileurl', this.singlejudg.fileurl);
    }
  }

  cancelfileurl() {
    this.isfileurlEditable = false;
  }

  editfile_url() {
    this.isfile_urlEditable = true;
    this.file_url = this.singlejudg.fileurl;
  }

  savefile_url(value: string) {
    // // // console.log(value);
    this.isfile_urlEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.file_url);
      if (this.file_url === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.file_url !== '') {
        this.singlejudg.file_url = this.file_url;
        // // // console.log('fileurl', this.singlejudg.file_url);
      }
    } else if (value === '') {
      this.singlejudg.file_url = this.file_url;
      // // // console.log('fileurl', this.singlejudg.file_url);
    }
  }

  cancelfile_url() {
    this.isfile_urlEditable = false;
  }

  editjudgement() {
    this.isJudgementEditable = true;
    this.jUdgement = this.singlejudg.judgement;
  }

  savejudgement(value: string) {
    this.isJudgementEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.jUdgement);
      if (this.jUdgement === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.jUdgement !== '') {
        this.singlejudg.judgement = this.jUdgement;
        // // // console.log('judgement', this.singlejudg.judgement);
      }
    } else if (value === '') {
      this.singlejudg.judgement = this.jUdgement;
      // // // console.log('judgement', this.singlejudg.judgement);
    }
  }

  canceljudgement() {
    this.isJudgementEditable = false;
  }

  editsection() {
    this.isSectionEditable = true;
    this.section = this.singlejudg.section;
  }

  savesection(value: string) {
    this.isSectionEditable = false;
    if (value !== '') {
      // // // console.log('test', this.section);
      if (this.section === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.section !== '') {
        this.singlejudg.section = this.section;
        // // // console.log('section', this.singlejudg.section);
      }
    } else if (value === '') {
      this.singlejudg.section = this.section;
      // // // console.log('section', this.singlejudg.section);
    }
  }

  cancelsection() {
    this.isSectionEditable = false;
  }

  editorder() {
    this.isorderEditable = true;
    this.order = this.singlejudg.order;
  }

  saveorder(value: string) {
    this.isorderEditable = false;
    if (value !== '') {
      // // // console.log('hi');
      // // // console.log('test', this.order);
      if (this.order === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.order !== '') {
        this.singlejudg.order = this.order;
        // // // console.log('order', this.singlejudg.order);
      }
    } else if (value === '') {
      this.singlejudg.order = this.order;
      // // // console.log('order', this.singlejudg.order);
    }
  }

  cancelorder() {
    this.isorderEditable = false;
  }

  editarticle() {
    this.isArticleEditable = true;
    this.article = this.singlejudg.article;
  }

  savearticle(value: string) {
    this.isArticleEditable = false;
    if (value !== '') {
      // // // console.log('test', this.article);
      if (this.article === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.article !== '') {
        this.singlejudg.article = this.article;
        // // // console.log('article', this.singlejudg.article);
      }
    } else if (value === '') {
      this.singlejudg.article = this.article;
      // // // console.log('article', this.singlejudg.article);
    }
  }

  cancelarticle() {
    this.isArticleEditable = false;
  }

  editrule() {
    this.isRuleEditable = true;
    this.rule = this.singlejudg.rule;
  }

  saverule(value: string) {
    this.isRuleEditable = false;
    if (value !== '') {
      // // // console.log('test', this.rule);
      if (this.rule === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.rule !== '') {
        this.singlejudg.rule = this.rule;
        // // // console.log('rule', this.singlejudg.rule);
      }
    } else if (value === '') {
      this.singlejudg.rule = this.rule;
      // // // console.log('rule', this.singlejudg.rule);
    }
  }

  cancelrule() {
    this.isRuleEditable = false;
  }

  editActStatueName() {
    this.isActStatueNameEditable = true;
    this.act_statue_name = this.singlejudg.act_statue_name;
  }

  saveActStatueName(value: string) {
    this.isActStatueNameEditable = false;
    if (value !== '') {
      // // // console.log('test', this.act_statue_name);
      if (this.act_statue_name === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.act_statue_name !== '') {
        this.singlejudg.act_statue_name = this.act_statue_name;
        // // // console.log('act_statue_name', this.singlejudg.act_statue_name);
      }
    } else if (value === '') {
      this.singlejudg.act_statue_name = this.act_statue_name;
      // // // console.log('act_statue_name', this.singlejudg.act_statue_name);
    }
  }

  cancelActStatueName() {
    this.isActStatueNameEditable = false;
  }

  editHighcourt() {
    this.isHighcourtEditable = true;
    this.Highcourt = this.singlejudg.Highcourt;
  }

  saveHighcourt(value: string) {
    this.isHighcourtEditable = false;
    if (value !== '') {
      // // // console.log('test', this.Highcourt);
      if (this.Highcourt === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.Highcourt !== '') {
        this.singlejudg.Highcourt = this.Highcourt;
        // // // console.log('Highcourt', this.singlejudg.Highcourt);
      }
    } else if (value === '') {
      this.singlejudg.Highcourt = this.Highcourt;
      // // // console.log('Highcourt', this.singlejudg.Highcourt);
    }
  }

  cancelHighcourt() {
    this.isHighcourtEditable = false;
  }

  editHighcourtdecision() {
    this.isHighcourtDecisionEditable = true;
    this.hc_decision = this.singlejudg.hc_decision;
  }

  saveHighcourtdecision(value: string) {
    this.isHighcourtDecisionEditable = false;
    if (value !== '') {
      // // // console.log('test', this.hc_decision);
      if (this.hc_decision === '') {
        // // // console.log('hi');
        this.snackBar.open("This field can't be empty, kindly enter a value", "Ok", {
          duration: 3000
        });
      } else if (this.hc_decision !== '') {
        this.singlejudg.hc_decision = this.hc_decision;
        // // // console.log('Highcourt decision', this.singlejudg.hc_decision);
      }
    } else if (value === '') {
      this.singlejudg.hc_decision = this.hc_decision;
      // // // console.log('Highcourt decision', this.singlejudg.hc_decision);
    }
  }

  cancelHighcourtdecision() {
    this.isHighcourtDecisionEditable = false;
  }

  saveFile(text: BIFR) {
    // // console.log('text', text);
    this.judgementService.generateHTML(text).subscribe(data => {
      // // console.log('data', data);

      if ( data.success === true ) {
        this.snackBar.open('HTML generated successfully!', 'OK', {
          duration: 3000
        });
      } else if ( data.success === false ) {
        this.snackBar.open('HTML generation failed!', 'OK', {
          duration: 5000
        });
      }
    });
  }

  submitForm() {
    this.singlejudg.old_citation = this.selectedCitation;
    this.showEditPB = true;
    this.singlejudg.DOJ = null;
    console.log("in home",this.singlejudg._id)
    this.judgementService.updateSingleJudgementById(this.singlejudg._id, this.selectedTribunal.value, this.singlejudg).subscribe(data => {
      this.resultjudg = data;
      this.showEditPB = false;
      this.snackBar.open('Updated successfully in mongo', 'Ok', {
        duration: 2000
      });
    });
    this.judgementService.getSingleJudgementByCitationElastic(this.selectedCitation, this.selectedTribunal.value ).subscribe(
      judgement => {
      if(judgement.hits.hits.length <= 0 ){
        this.elasticId = 'No Id';
      }else{
        this.elasticId = judgement.hits.hits[0]._id;
      }
    });

    console.log('eid', this.elasticId);
    this.judgementService.updateSingleJudgementByElasticId(this.elasticId, this.selectedTribunal.value, this.singlejudg).subscribe(edata => {
      setTimeout(() => {
        this.snackBar.open('Updated successfully!', 'Ok', {
          duration: 2000
        });
      }, 2010);
    });

  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['/login']);
  }

  createJudgementForm() {
    this.addJudgementForm = this.fb.group({
      latest_important: '',
      Citation: ['', Validators.required],
      equiCitation: '',
      law_topic: [''],
      Case_No: ['', Validators.required],
      Petitioner: '',
      Respondent: '',
      Adv_petitioner: '',
      Adv_respondent: '',
      HeadNote: '',
      Judge_name: '',
      Court: '',
      cases_cited: '',
      District: '',
      Date: '',
      decision: '',
      fileurl: '',
      pdfurl:'',
      tribunal: '',
      rule: '',
      order: '',
      section: '',
      article: '',
      act_statue_name: '',
      Highcourt: '',
      Highcourt_decision: '',
      judgement: '',
      headline: '',
      important: '',
      often_cited: '',
      landmark: '',
      eid: new FormControl(Validators.required, Validators.pattern('[0-9]*'))
    });
  }



  submitAddJudgementForm() {
    this.isSubmitDisable = true;
    this.addFormProgressBarflag = true;
    console.log('************* Submit Clicked *******************')
    // // // console.log('submitbtnstate', this.isSubmitDisable);
    this.judgForm = this.addJudgementForm.value;
    console.log('Judgement Form : ',this.judgForm)
    var filename = this.judgForm.fileurl;
    if ( this.judgForm.tribunal === 'Supreme Court' ) {
      var tribFolder = 'SC';
    } else if ( this.judgForm.tribunal === 'MumbaiHC' ) {
      var tribFolder = 'MumbaiHC';
    } else if ( this.judgForm.tribunal === 'DelhiHC' ) {
      var tribFolder = 'DelhiHC';
    } else if ( this.judgForm.tribunal === 'GujaratHC' ) {
      var tribFolder = 'GujaratHC';
    } else if ( this.judgForm.tribunal === 'KarnatakaHC' ) {
      var tribFolder = 'KarnatakaHC';
    } else if ( this.judgForm.tribunal === 'AllahabadHC' ) {
      var tribFolder = 'AllahabadHC';
    } else if ( this.judgForm.tribunal === 'KeralaHC' ) {
      var tribFolder = 'KeralaHC';
    } else if ( this.judgForm.tribunal === 'PunjabHC' ) {
      var tribFolder = 'PunjabHC';
    } else if ( this.judgForm.tribunal === 'CalcuttaHC' ) {
      var tribFolder = 'CalcuttaHC';
    } else if (this.judgForm.tribunal === 'MadrasHC') {
      var tribFolder = 'MadrasHC';
    }else if (this.judgForm.tribunal === 'TripuraHC') {
      var tribFolder = 'TripuraHC';
    }else if (this.judgForm.tribunal === 'RajasthanHC') {
      var tribFolder = 'RajasthanHC';
    }else if (this.judgForm.tribunal === 'OrrisaHC') {
      var tribFolder = 'OrrisaHC';
    }else if (this.judgForm.tribunal === 'HimachalPradeshHC') {
      var tribFolder = 'HimachalPradeshHC';
    }

    var yearFolder = this.judgForm.Citation.substr(0, 4);
    // var fileUrl = 'https://nearlaw.com/PDF/' + tribFolder + '/'  + yearFolder + '/' + filename;
    var fileUrl = tribFolder + '/'  + yearFolder + '/' + filename;
    this.judgForm.fileurl = fileUrl;
    this.judgForm.pdfurl = fileUrl.replace('.html','.pdf');
    // var current_date = new Date();
    // var date = current_date.toISOString();
    // this.judgForm.createdOn = date;

    // // // console.log('filename', filename);
    // // // console.log('tribfolder', tribFolder);
    // // // console.log('yearfolder', yearFolder);
    // // // console.log('fileurl', fileUrl);
    // // // console.log('form', this.judgForm);
    // // // console.log('form', this.judgForm);
    var  numberValidator: string
    // // // console.log('formvalue', this.judgForm);
    // // // console.log('test', this.judgForm.eid);

    // Form Validation
    if (this.judgForm.Citation === '' || this.judgForm.Case_No === '' || this.judgForm.tribunal === '' || this.judgForm.Court === '' || typeof this.judgForm.eid !== 'number' || this.judgForm.Date === '') {
      this.isSubmitDisable = false;
      this.addFormProgressBarflag = false;
      this.isFormValid = false;
      this.snackBar.open('Kindly fill all required fields!', 'Ok', {
        duration: 3000
      });
    } else {
      this.isFormValid = true;
      this.judgForm.equivalent_citation = this.equiCits;
      this.judgForm.law_topic = this.lawTopicsValue;
      // // console.log('judgform', this.judgForm);
      // const doj_sort = Date.parse(this.judgForm.Date);
      this.judgForm.Date;
      console.log(this.judgForm.Date);
      const doj_sort = new Date(this.judgForm.Date);

      //console.log('doj_sort', doj_sort);

      this.judgementService.addSingleJudgement(this.judgForm, this.judgForm.tribunal).subscribe(data => {
        console.log("judgForm : ",this.judgForm);
        console.log("judgetribunal : ",this.judgForm.tribunal);

        this.isSubmitDisable = false;
        this.addFormProgressBarflag = false;
        // // console.log('submitbtnstate', this.isSubmitDisable);
        this.judgForm = data;
        // // console.log('result', this.judgForm);
        if (data) {
          this.snackBar.open('Data added successfully!', 'OK', {
            duration: 2000
          });
          this.addJudgementForm.reset({
            latest_important: '',
            Citation: '',
            equiCitation: '',
            law_topic: [''],
            Case_No: '',
            Petitioner: '',
            Respondent: '',
            Adv_petitioner: '',
            Adv_respondent: '',
            HeadNote: '',
            Judge_name: '',
            Court: '',
            cases_cited: '',
            District: '',
            Date: '',
            decision: '',
            fileurl: '',
            pdfurl:'',
            tribunal: '',
            order: '',
            section: '',
            rule: '',
            article: '',
            act_statue_name: '',
            Highcourt: '',
            Highcourt_decision: '',
            judgement: '',
            headline: '',
            eid: 0,
            important: '',
            often_cited: '',
            landmark: ''
          });
        } else {
          this.snackBar.open('Error was occured while addng data!', 'OK', {
            duration: 2000
          });
        }
      });
    }
  }

  // @ViewChild('judgementCard') judgmtCard;
  onchangeMarker() {
    this.isMarkerChecked = !this.isMarkerChecked;
    if ( this.isMarkerChecked === true ) {
      // if ( this.singlejudg.Marked === true ) {
      //   this.isMarkerChecked = true;
        this.singlejudg.Marked = true;
      //   // // console.log('marker', this.singlejudg.Marked);
      //   this.judgementService.updateSingleJudgementById(this.judgement[0]._id, this.selectedTribunal.value, this.singlejudg).subscribe(data => {
      //     this.resultjudg = data;
      //     // // console.log(data);
      //     // // console.log('data', this.singlejudg);
      //   });
      //   // this.judgmtCard.css({'background-color': 'red'});
      // } else if ( this.singlejudg.Marked === false || this.singlejudg.Marked === undefined) {
      //   this.isMarkerChecked = false;
      //   this.singlejudg.Marked = false;
        // // // console.log('marker', this.singlejudg.Marked);
        this.judgementService.updateSingleJudgementById(this.judgement[0]._id, this.selectedTribunal.value, this.singlejudg).subscribe(data => {
          this.resultjudg = data;
          // // // console.log(data);
          // // // console.log('data', this.singlejudg);
        });
      // }

      // this.judgmtCard.css({'background-color': 'green'});
    } else if ( this.isMarkerChecked === false ) {
      // if ( this.singlejudg.Marked === true ) {
      //   this.isMarkerChecked = true;
      //   // this.singlejudg.Marked = true;
      //   // // console.log('marker', this.singlejudg.Marked);
      //   this.judgementService.updateSingleJudgementById(this.judgement[0]._id, this.selectedTribunal.value, this.singlejudg).subscribe(data => {
      //     this.resultjudg = data;
      //     // // console.log(data);
      //     // // console.log('data', this.singlejudg);
      //   });
      //   // this.judgmtCard.css({'background-color': 'red'});
      // } else if ( this.singlejudg.Marked === false || this.singlejudg.Marked === undefined) {
      //   this.isMarkerChecked = false;
        this.singlejudg.Marked = false;
        // // // console.log('marker', this.singlejudg.Marked);
        this.judgementService.updateSingleJudgementById(this.judgement[0]._id, this.selectedTribunal.value, this.singlejudg).subscribe(data => {
          this.resultjudg = data;
          // // // console.log(data);
          // // // console.log('data', this.singlejudg);
        });
      // }
    }
  }

  deleteJudgement(id: string) {
    // // console.log('id', id);
    this.judgementService.deleteJudgementById(id, this.selectedTribunal.value).subscribe(data => {
      // // console.log('data', data);
      this.snackBar.open('Data successfully deleted from mongo!', 'OK', {
        duration: 2000
      });
    });

    // setTimeout(
    //   function() {
        this.judgementService.deleteJudgementByElasticId(this.elasticId, this.selectedTribunal.value).subscribe(data => {
          // // console.log('data', data);

          this.snackBar.open('Data successfully deleted from mongo!', 'OK', {
            duration: 2000
          });
        });
    //   },
    //   3000
    // );
  }

  getTrialUsers() {
    var currentUnixTime = Date.now();
    // // console.log('currentunix', currentUnixTime);
    var d = new Date(currentUnixTime);
    //1534743513214
    // var currentdate = unixTime(currentUnixTime);
    // // console.log('currentdate', d);

    var start = new Date(d.setHours(0, 0, 0, 0));
    var end = new Date(d.setHours(23, 59, 59, 999));
    // // console.log('start', start);
    // // console.log('end', end);

    this.authService.getTrialUser(start, end).subscribe(data => {

      this.trialUsers = data;
      // // console.log('trialusers', this.trialUsers);

      if ( data.length === 0 ) {
        this.snackBar.open('No records found!', 'OK', {
          duration: 4000
        });
      }

      if ( data.err ) {
        this.snackBar.open('There was an error, please try again!', 'OK');
      }
    });
  }

  addEquiCit(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.equiCits.length < 6) {

      if ((value || '').trim()) {
        this.equiCits.push(value.trim());
      }

      // //('keywords', this.keywords);
      // Reset the input value
      if (input) {
        input.value = '';
      }


    } else if (this.equiCits.length >= 6) {
      this.snackBar.open('You cannot add more than six equivalent citations!', 'OK', {
        duration: 5000
      });
    }
  }

  removeEquiCit(data): void {
    const index = this.equiCits.indexOf(data);

    if (index >= 0) {
      this.equiCits.splice(index, 1);
    }
  }

  lawtopicChange(event) {
    // // console.log('event', event);
    // // console.log('hi');
    this.lawTopicsValue = event;
  }

  onPartyNameSearch() {
    this.citationList = [];
    this.judgmentList = [];
    const petitionerName = this.petitionerName.trim();
    const respondentName = this.respondentName.trim();
    let query = '';
    if ( petitionerName !== '' && respondentName === '' && this.selectedTribunal.value !== '' ) {
      query = '(Petitioner:' + petitionerName + ') AND (tribunal:"' + this.selectedTribunal.value + '")' ;
    } else if ( petitionerName === '' && respondentName !== '' && this.selectedTribunal.value !== '') {
      query = '(Respondent:' + respondentName + ') AND (tribunal:"' + this.selectedTribunal.value + '")';
    } else if ( petitionerName !== '' && respondentName !== '' && this.selectedTribunal.value !== '') {
      query = '(Petitioner:' + petitionerName + ') AND (Respondent:' + respondentName + ') AND (tribunal:"' + this.selectedTribunal.value + '")';
    } else if ( petitionerName === '' && respondentName === '' ) {
      this.snackBar.open('Petitioner or Respondent field cannot be empty!', 'OK', {
        duration: 4000
      });
    }
    // // console.log('query', query);
    this.showPB = true;
    this.judgementService.search(query).subscribe((data) => {
      // console.log('data', data);
      this.showPB = false;
      // // console.log(data.hits.hits[0]._source);
      console.log(data);
      if ( data.length > 0 ) {
        this.citationList = data;
        // console.log('citationList', this.citationList);
        this.showSelectCitaion = true;
      }
      // if ( data.hits.total > 0 ) {
      //   for ( let i = 0; i < data.hits.hits.length; i++ ) {
      //     this.citationList.push(data.hits.hits[i]._source.Citation);
      //     this.judgmentList.push(data.hits.hits[i]._source);
      //     this.elasticIDList.push(data.hits.hits[i]._id);
      //   }

      //   // // console.log('citationList', this.citationList);

      //   this.showSelectCitaion = true;


      // }
    });

    // if ( petitionerName !== '' || respondentName !== '' ) {
    //   this.judgementService.
    // }
  }

  onCitationSelected(event: MatSelectChange) {
    const citation = event.value;
    console.log(citation)
    this.showPB = true;
    this.judgementService.getSingleJudg('Citation:"' + citation + '"').subscribe((resp) => {
      console.log('resp', resp);
      this.showPB = false;
      if ( resp.hits.total > 0 ) {
        this.singlejudg = resp.hits.hits[0]._source;
        this.elasticId = resp.hits.hits[0]._id;

        this.showResult = true;

        if (this.singlejudg.file_url !== undefined) {
          this.sclink = 'https://nearlaw.com/PDF/' + this.singlejudg.file_url;
          const check = this.sclink.substr(this.sclink.length - 4);
          // // console.log('sclink1', check);
          if ( check === 'html' ) {
            const temp = this.sclink.slice(0, -4);
            // // console.log('temp', temp);
            this.sclink1 = temp + 'pdf';
          }
          // // console.log('link', this.sclink1);
          // // // console.log('url', this.singlejudg.file_url);
        } else if (this.singlejudg.fileurl !== undefined) {
          this.sclink = 'https://nearlaw.com/PDF/' + this.singlejudg.fileurl;
          const check = this.sclink.substr(this.sclink.length - 4);
          // // console.log('sclink1', check);
          if ( check === 'html' ) {
            const temp = this.sclink.slice(0, -4);
            // // console.log('temp', temp);
            this.sclink1 = temp + 'pdf';
          }
          // // console.log('link', this.sclink1);
          // // // console.log('url', this.singlejudg.fileurl);
        }

      }
    });

    // for (let i = 0; i < this.judgmentList.length; i++ ) {
    //   if ( this.judgmentList[i].Citation === citation ) {
    //     this.singlejudg = this.judgmentList[i];

    //     // this.elasticId = this.elasticIDList[i];
    //     this.showResult = true;

    //     this.judgementService.getSingleJudgementByCitationElastic(this.singlejudg.Citation, this.singlejudg.tribunal).subscribe(judgement => {
    //       this.elasticId = judgement.hits.hits[0]._id;
    //       // // console.log('elastic Id', this.elasticId);
    //     });
    //   }
    // }

    this.judgementService.getSingleJudgementByCitation(citation, this.selectedTribunal.value).subscribe((data) => {
      console.log('data', data);
      this.singlejudg._id = data[0]._id;

    });



    // // console.log('singleJudg', this.singlejudg);
    // this.judgmentList.forEach((judgment) => {
    //   if ( judgment.Citation === citation ) {
    //     this.singlejudg = judgment;
    //     // this.judgement[0] = judgment;
    //     this.elasticId = judgment
    //     this.showResult = true;
    //   }
    // });

    // // // console.log('judgement', this.judgement[0]);
  }

  onCitationSelectedDate(event: MatSelectChange) {
    const citation = event.value;
    // console.log('yo', citation);
    this.singlejudg = citation;
    // console.log('singleJudg', this.singlejudg);
    // this.showResult = true;

    // this.showPB = true;
    // this.judgementService.getSingleJudg('Citation:"' + citation + '"').subscribe((resp) => {
    //   // console.log('resp', resp);
    //   this.showPB = false;
    //   if ( resp.hits.total > 0 ) {
    //     this.singlejudg = resp.hits.hits[0]._source;
    //     this.elasticId = resp.hits.hits[0]._id;

    //     this.showResult = true;

    //   }
    // });

    this.judgementService.getSingleJudgementByCitationElastic( citation.Citation, this.selectedTribunalDate).subscribe((data) => {
      // console.log('data', data);
      this.elasticId = data.hits.hits[0]._id;
    });

    if (this.singlejudg.file_url !== undefined) {
      this.sclink = 'https://nearlaw.com/PDF/' + this.singlejudg.file_url;
      const check = this.sclink.substr(this.sclink.length - 4);
      // // console.log('sclink1', check);
      if ( check === 'html' ) {
        const temp = this.sclink.slice(0, -4);
        // // console.log('temp', temp);
        this.sclink1 = temp + 'pdf';
      }
      // // console.log('link', this.sclink1);
      // // // console.log('url', this.singlejudg.file_url);
    } else if (this.singlejudg.fileurl !== undefined) {
      this.sclink = 'https://nearlaw.com/PDF/' + this.singlejudg.fileurl;
      const check = this.sclink.substr(this.sclink.length - 4);
      // // console.log('sclink1', check);
      if ( check === 'html' ) {
        const temp = this.sclink.slice(0, -4);
        // // console.log('temp', temp);
        this.sclink1 = temp + 'pdf';
      }
      // // console.log('link', this.sclink1);
      // // // console.log('url', this.singlejudg.fileurl);
    }

    // for (let i = 0; i < this.dateSearchResultList.length; i++ ) {
    //   if ( this.dateSearchResultList[i].Citation === citation.Citation ) {

    //   }
    // }
  }

  onSearchByDate() {
    // console.log('tribunal', this.selectedTribunalDate);
    if ( this.selectedTribunalDate !== '' && this.dateFrom !== null && this.dateTo !== null ) {
      // // console.log('date', this.selectedDateRange);
      // const datenow = new Date(Date.now());
      // const b = datenow.toISOString().replace('T', ' ').slice(0, -5);
      // // console.log('yo', datenow.toISOString());
      // const todayDate = datenow.getDate();
      // const todayMonth = datenow.getMonth();
      // // console.log('todaymonth', todayMonth);
      // let yesterdayDate: Date = null;
      // let lastWeekDate: Date = null;
      // let lastMonthDate: Date = null;
      // let lastSixMonthDate: Date = null;
      const query = 'doj_sort:["' + this.dateFrom.toISOString().replace('T', ' ').slice(0, -5)
        + '"+TO+"' + this.dateTo.toISOString().replace('T', ' ').slice(0, -5) + '"] AND tribunal:"'
        + this.selectedTribunalDate + '"';





      // if ( this.selectedDateRange === 'Yesterday' ) {
      //   // yesterdayDate = datenow.setDate(todayDate - 1);
      //   const test = datenow.setDate(todayDate - 1);
      //   yesterdayDate = new Date(test);
      //   // console.log('yesterdayDate', yesterdayDate.toISOString());
      //   const a = yesterdayDate.toISOString().replace('T', ' ').slice(0, -5);

      //   query = 'doj_sort:["' + a + '"+TO+"' + b + '"] AND tribunal:"' + this.selectedTribunalDate + '"';
      // } else if ( this.selectedDateRange === 'Last Week' ) {
      //   const test = datenow.setDate(todayDate - 7);
      //   lastWeekDate = new Date(test);
      //   // console.log('lastWeekDate', lastWeekDate.toISOString());
      //   const a = lastWeekDate.toISOString().replace('T', ' ').slice(0, -5);
      //   query = 'doj_sort:["' + a + '"+TO+"' + b + '"] AND tribunal:"' + this.selectedTribunalDate + '"';
      // } else if ( this.selectedDateRange === 'Last Month' ) {
      //   const test = datenow.setMonth(todayMonth - 1);    // plus 1 bcoz getMonth method fetches previous month instead of current month
      //   lastMonthDate = new Date(test);
      //   // console.log('lastMonthDate', lastMonthDate.toISOString());
      //   const a = lastMonthDate.toISOString().replace('T', ' ').slice(0, -5);
      //   query = 'doj_sort:["' + a + '"+TO+"' + b + '"] AND tribunal:"' + this.selectedTribunalDate + '"';
      // } else if ( this.selectedDateRange === 'Last Six Months' ) {
      //   const test = datenow.setMonth(todayMonth - 6);
      //   lastSixMonthDate = new Date(test);
      //   // console.log('lastSixMonthDate', lastSixMonthDate.toISOString());
      //   const a = lastSixMonthDate.toISOString().replace('T', ' ').slice(0, -5);
      //   query = 'doj_sort:["' + a + '"+TO+"' + b + '"] AND tribunal:"' + this.selectedTribunalDate + '"';
      // }

      // this.showDateSearchPB = true;
      this.showPB = true;
      this.judgementService.getSingleJudg(query).subscribe((data) => {
        // console.log(data);
        this.dateSearchResultList = [];
        this.showPB = false;
        // this.showDateSearchPB = false;
        if ( data.hits.total > 0 ) {
          // this.dateSearchResultList = data;
          for ( let i = 0; i < data.hits.total; i++ ) {
            this.dateSearchResultList.push(data.hits.hits[i]._source);
          }
          // console.log('dateSearchResultList', this.dateSearchResultList);
        } else {
          this.snackBar.open('No results found!', 'OK', {
            duration: 4000
          });
        }
      });
    }
  }

  handleFileInput(files: FileList) {
    this.uploadFile = files.item(0);
    // console.log('file', this.uploadFile);
    if ( this.uploadFile.type !== 'application/pdf' ) {
      this.snackBar.open('Only PDFs are alllowed', 'OK', {
        duration: 3500
      });
      this.uploadDisabled = true;
    } else {
      // console.log(this.uploadFile.type);
      this.uploadDisabled = false;
    }
  }

  onSelectedTab( event: MatTabChangeEvent) {
    // console.log('event', event.tab.textLabel);
    const tabValue = event.tab.textLabel;
    if ( tabValue === 'SC upload' ) {
      this.tabTribunal = 'Supreme Court';
    } else if ( tabValue === 'MumbaiHC upload' ) {
      this.tabTribunal = 'MumbaiHC';
    }else if ( tabValue === 'KeralaHC upload' ) {
      this.tabTribunal = 'KeralaHC';
    }else if ( tabValue === 'MadrasHC upload' ) {
      this.tabTribunal = 'MadrasHC';
    }else if ( tabValue === 'UttarakhandHC upload' ) {
      this.tabTribunal = 'UttarakhandHC';
    }else if ( tabValue === 'HimachalPradeshHC upload' ) {
      this.tabTribunal = 'HimachalPradeshHC';
    }else if ( tabValue === 'RajasthanHC upload' ) {
      this.tabTribunal = 'RajasthanHC';
    } else if ( tabValue === 'TripuraHC upload' ) {
      this.tabTribunal = 'TripuraHC';
    } else if ( tabValue === 'MadhyaPradeshHC upload' ) {
      this.tabTribunal = 'MpHC';
    } else if ( tabValue === 'KarnatakaHC upload' ) {
      this.tabTribunal = 'KarnatakaHC'
    } else if ( tabValue === 'GujaratHC upload') {
      this.tabTribunal = 'GujaratHC';
    } else if ( tabValue === 'DelhiHC upload' ) {
      this.tabTribunal = 'DelhiHC';
    }else if ( tabValue === 'PunjabHC upload' ) {
      this.tabTribunal = 'PunjabHC';
    } else if ( tabValue === 'AllahabadHC upload' ) {
      this.tabTribunal = 'AllahabadHC';
    } else if ( tabValue === 'CalcuttaHC upload' ) {
      this.tabTribunal = 'CalcuttaHC';
    } else if ( tabValue === 'OrrisaHC upload' ) {
      this.tabTribunal = 'OrrisaHC';
    }
  }

  uploadFlie() {
    this.showPB = true;
    console.log('tabtrib', this.tabTribunal);
    this.judgementService.uploadFile(this.uploadFile, this.tabTribunal).subscribe((resp) => {
      // console.log('resp', resp);
      this.showPB = false;

      if ( resp.success === true ) {
        this.snackBar.open('File Uploaded successfully!', 'OK', {
          duration: 3500
        });
      } else {
        this.snackBar.open('Upload failed!', 'OK', {
          duration: 3500
        });
      }
    });
  }
}
