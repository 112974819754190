import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatSidenavModule,
  MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule, MatTooltipModule, MatSortModule, MatExpansionModule,
  MatSlideToggleModule, MatToolbarModule, MatListModule, MatGridListModule, MatSnackBarModule, MatPaginatorModule, MatTabsModule,
  MatCardModule, MatIconModule, MatProgressSpinnerModule, MatDialogModule, MatTableModule, MatAutocompleteModule, MatProgressBarModule,
  MatChipsModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
// import { RestangularModule, Restangular } from 'ngx-restangular';
// import { AngularFileUploaderModule } from "angular-file-uploader";
import { FileSaverModule } from 'ngx-filesaver';

import 'hammerjs';

import { baseURL } from './shared/baseurl';
// import { RestangularConfigFactory } from './shared/restConfig';

import { AuthService } from './services/auth.service';
import { JudgementService } from './services/judgement.service';
import { ProcessHttpmsgService } from './services/process-httpmsg.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatSidenavModule,
    MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule, MatTooltipModule, MatSortModule, MatChipsModule,
    MatSlideToggleModule, MatToolbarModule, MatListModule, MatGridListModule, MatSnackBarModule, MatPaginatorModule,
    MatCardModule, MatIconModule, MatProgressSpinnerModule, MatDialogModule, MatTableModule, MatExpansionModule,
    MatAutocompleteModule, MatProgressBarModule, MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    // RestangularModule.forRoot(RestangularConfigFactory),
    // AngularFileUploaderModule,
    FileSaverModule
  ],
  providers: [
    { provide: 'BaseURL', useValue: baseURL },
    AuthService,
    ProcessHttpmsgService,
    JudgementService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
