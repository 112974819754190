import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
// import { tokenNotExpired } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';

import { baseURL } from '../shared/baseurl';
import { User } from '../shared/user';
import { ProcessHttpmsgService } from './process-httpmsg.service';

import { map, delay, catchError} from 'rxjs/operators';

interface AuthResponse {
  status: string,
  success: string,
  token: string
};

interface JWTResponse {
  status: string,
  success: string,
  user: any
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  tokenKey: string = 'JWT';
  isAuthenticated: Boolean = false;
  username: Subject<string> = new Subject<string>();
  authToken: string = undefined;
  user: any;
  

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService,
    // private helper: JwtHelperService
  ) { }

  checkJWTtoken() {
    this.http.get<JWTResponse>('http://localhost:3000/users/checkJWTtoken')
    .subscribe(res => {
      // console.log("JWT Token Valid: ", res);
      //this.sendUsername(res.user.username);
    },
    err => {
      // console.log("JWT Token invalid: ", err);
      this.destroyUserCredentials();
    });
  }

  sendUsername(name: string) {
    this.username.next(name);
  }

  clearUsername() {
    this.username.next(undefined);
  }

  loadUserCredentials() {
    var credentials = JSON.parse(localStorage.getItem(this.tokenKey));
    // console.log("loadUserCredentials ", credentials);
    if (credentials && credentials.username != undefined) {
      this.useCredentials(credentials);
      if (this.authToken)
        this.checkJWTtoken();
    }
  }

  storeUserData(token, user, Id) {
    localStorage.setItem('jwt', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('Id', JSON.stringify(Id));
    localStorage.setItem('type', JSON.stringify(user.type));
    this.authToken = token;
    this.user = user;
  }

  useCredentials(credentials: any) {
    this.isAuthenticated = true;
    this.sendUsername(credentials.username);
    this.authToken = credentials.token;
  }

  destroyUserCredentials() {
    this.authToken = undefined;
    this.clearUsername();
    this.isAuthenticated = false;
    localStorage.clear();
  }

  signUp(user: any): Observable<any> {
    return this.http.post(baseURL + 'signup',
      {"firstname": user.firstname, "lastname": user.lastname, "username": user.username, "password": user.password})
      .pipe(catchError(error => { return this.processHTTPMsgService.handleError(error); }))
        
  }

  logIn(user: any, otp): Observable<any> {
    return this.http.post(baseURL + 'login',
      {'username': user.username, 'password': user.password, 'otp': otp})
      //user)
      /*.map(res => {
        this.storeUserCredentials({user: user, token: res.token});
        return {'success': true, 'user': user };
      })*/
      .pipe(catchError(error => {
        return this.processHTTPMsgService.handleError(error); }));
  }

  getOtp(user: User): Observable<any> {
    return this.http.post(baseURL + 'otp', {'username': user.username, 'password': user.password})
      .pipe(catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  logOut() {
    this.destroyUserCredentials();
  }

  isLoggedIn(): Boolean {
    return this.isAuthenticated;
  }

  // isExpired(token): Boolean {
  //   return this.helper.isTokenExpired(token);
  // }

  getToken(): string {
    const token = localStorage.getItem('jwt');
    return token;
  }

  getTrialUser(start, end): Observable<any> {
    let params = new HttpParams();
    params.set('start', start);
    params.set('end', end);

    const z = 'abc';

    return this.http.post(baseURL + 'getTrialUser?start=' + start + '&end=' + end, z, {params: params})
    .pipe(map(res => res), catchError(error => {
      return this.processHTTPMsgService.handleError(error);
    }));
  }
}
